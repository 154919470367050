<template>
  <div class="order giac">
    <!-- 渠道票种+通票 -->
    <slot v-if="isdiy">
      <Title title="购买门票"></Title>
      <div class="common">
        <BuyTicket :qd="hash||''" :hash="hash&&active==='special'?hash:''"></BuyTicket>
        <Tips :meetTime="meetTime"></Tips>
      </div>
    </slot>
    <!-- 渠道票种单独展示 -->
    <slot v-else>
      <Title title="购买门票" v-if="active !== 'special'"></Title>
      <div class="common" v-if="active === 'common'">
        <BuyTicket :qd="hash||''"></BuyTicket>
        <Tips :meetTime="meetTime"></Tips>
      </div>
      <iframe
        v-if="active === 'special'"
        style="width: 100%; height: 1500px; border: none;"
        :src="`https://case.msup.com.cn/pay/pay-submit.html?hash=${hash}`"
      ></iframe>
      <!-- margin-top: 120px -->
    </slot>

    <div class="line"></div>
  </div>
</template>

<script>
import { sid, getUserInfo, checkHash, getMeetingTime } from "@/utils/api.js";
export default {
  name: "Order",
  components: {
    Title: () => import("@/components/Title"),
    Tips: () => import("@/components/order/Tips.vue"),
    BuyTicket: () => import("@/components/order/BuyTicket.vue"),
  },
  data() {
    return {
      type: "",
      meetTime: "",
      hash: "",
      isdiy: false,
      sid: "",
      active: "common",
      // userInfo: {},
    };
  },
  // created() {
  //   this.checkInfo();
  // },
  mounted() {
    this.getTime();
    this.getHash();
  },
  methods: {
    getTime() {
      this.$http
        .jsonp(`${getMeetingTime}${sid(this.global.year)}`)
        .then((data) => {
          return data.json();
        })
        .then((res) => {
        //   console.log(res);
          this.meetTime = this.formatTime(res.data.startTime, "start");
        });
    },
    formatTime(timestamp, tag) {
      const time = new Date(timestamp * 1000);
      const year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      if (tag === "start") {
        return `${year}年${month}月${day}日`;
      } else {
        return `${month}.${day}`;
      }
    },
    toLogin() {
      // 
    },
    // 检查个人信息
    checkInfo() {
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem("token");
        if (token && token !== null) {
          let url = `${getUserInfo}?token=${token}`;
          this.$http
            .jsonp(url)
            .then((data) => {
              return data.json();
            })
            .then((res) => {
              console.log("order- checkinfo",res);
              if (res.status === "200") {
                // this.userInfo = res.data;
                for (const key in res.data) {
                  if (!res.data[key] || res.data[key]==="") {
                    if (window.confirm("购票需要您先完善个人信息")) {
                      let link = window.location.pathname + window.location.search;
                      localStorage.setItem('link_before_complete', link);
                      this.$router.push({
                        path: `/${this.global.year}/userCenter`,
                        // query: { active: "5" },
                        query: { selected: "2" },
                      });
                    } else {
                      this.$router.push({ path: '/2024sz/home' });
                    }
                    resolve(false);
                    return false;
                    // break;
                  }
                }
                resolve(true);
              } else if (res.code === "1001" || res.status === "1011") {
                this.$message({ message: "当前登录状态已过期，请重新登录", type: "warning" });
                window.localStorage.removeItem("logins");
                window.localStorage.removeItem("token");
                let order = `/${this.global.year}/order`+`${this.global.hashParam.qd?'?qd='+this.global.hashParam.qd:''}`;
                window.localStorage.setItem("linkurl", order);
                setTimeout(() => {
                  this.$router.push({ path: `/${this.global.year}/login` });
                }, 2000);
                resolve(false);
                return false;
              } else {
                console.log("获取用户信息失败");
                resolve(false);
                return false;
              }
            });
        } else {
          this.$message({ message: "当前登录状态已过期，请重新登录", type: "warning" });
          window.localStorage.removeItem("logins");
          window.localStorage.removeItem("token");
          let order = `/${this.global.year}/order`+`${this.global.hashParam.qd?'?qd='+this.global.hashParam.qd:''}`;
          window.localStorage.setItem("linkurl", order);
          setTimeout(() => {
            this.$router.push({ path: `/${this.global.year}/login` });
          }, 2000);
          resolve(false);
          return false;
        }
      })
    },
    getHash() {
      // const { hash, sid, type } = this.$route.query;
      // this.hash = hash;
      // this.sid = sid;
      // this.type = type;

      // console.log(this.global.hashParam);
      // this.hash = this.$route.query.qd ? this.$route.query.qd : this.global.hashParam.qd;
      // this.hash === undefined
      this.hash = this.$route.query && this.$route.query.qd || false;
      // user-info：
      this.isdiy = this.$route.query && this.$route.query.diy || false; // 部分渠道票种如出品人票最好还是单独展示故暂不启用diy参数
      if (!this.hash) {
        return false;
      } else {
        this.global.hashParam.qd = this.hash;
        this.isHashTrue();
      }
    },
    // 判断hash是否合格(qd是否正确)
    isHashTrue() {
      this.$http
        .jsonp(`${checkHash}?hash=${this.hash}`)
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          // console.log('qd ', res);
          if (res.status === "200") {
            this.active = "special";
            // window.open(
            //   `https://www.msup.com.cn/shopping/default/shopping-tickect?sid=${this.sid}&amp;qd=${this.hash}`
            // );
          } else {
            this.active = "common";
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/reset.css";

.giac {
  --main-color: #003177;
}
.order {
  .line {
    width: 100%;
    max-width: 1200px;
    height: 1px;
    margin: 0 auto;
    background: #f1f4f7;
  }
}
</style> 